let $ = jQuery
const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)


$(function () {
    onSubmitForm()
    changeImages()
    onResized()
    checkPage()
})

const $gallery = $('#gallery-carousel')

$gallery.on({
    click: function () {
        let $src = $('#gallery-carousel .owl-item.active.center  .item  img').attr('src')
        $('.frame-box').children('img').attr('src', $src)
    },
    touchend: function () {
        setTimeout(() => {
            let $src = $('#gallery-carousel .owl-item.active.center  .item  img').attr('src')
            $('.frame-box').children('img').attr('src', $src)
        }, 1)
    }
})

$gallery.owlCarousel({
    loop: true,
    margin: 10,
    nav: true,
    center: true,
    autoWidth: true,
    mouseDrag: true,
    toucheDrag: true,
    startPosition: 1
})

$(window).on({
    load: function () {
        let $src = $('#gallery-carousel .owl-item.active.center  .item  img').attr('src')
        $('.frame-box').children('img').attr('src', $src)
    }
})

$('#partners-carousel').owlCarousel({
    items: 8,
    loop: true,
    margin: 56,
    dots:  false,
    nav: false,
    center: false,
    autoWidth: true,
    mouseDrag: false,
    toucheDrag: false,
    pullDrag: false,
    startPosition:  2,
    autoplay: true,
    slideTransition: 'linear',
    autoplayTimeout: 6000,
    autoplaySpeed: 6000
})

$('.mobile-menu').on({
    click: function () {
        $(this).toggleClass('open close')
        $('header nav').toggle()
    }
})

$('.js-request-form').on({
    click: function () {
        $('.request-form').removeClass('d-none')
        $('.js-main-content').addClass('d-none')
    }
})

$('nav li a').on('click', function () {
    const $menu = $('.mobile-menu')
    const isOpen = $menu.css('display') === 'block';
    if(isOpen) {
        $('nav').hide()
        $menu.toggleClass('open close')
    }
})

function checkPage() {
    const pages = ['business', 'mfo', 'collection']
    if(pages.includes(page.name)) {
        $('.carousel-item').each(function () {
            $(this).removeClass('active')
        })
    }

    if (page.name !== undefined) {
        switch (page.name) {
            case 'business':
                $('#slide3').addClass('active')
                break
            case 'mfo':
                $('#slide2').addClass('active')
                break
            case 'collection':
                $('#slide4').addClass('active')
                break
            default:
                $('#slide1').addClass('active')

        }

        if(page.name === 'mfo') {
            ('#slide3').addClass('active')
        }

        if(page.name === 'business') {
            ('#slide3').addClass('active')
        }
    }
}

function changeImages() {
    const $imgPath = `/wp-content/themes/sector_rosta/assets/images`
    $('#heroCarousel div.carousel-item').each(function () {
        let $id = $(this).attr('id')
        let index = +/\d+/.exec($id)
        let $img = $(this).children('img');
        const $width = $(window).width()

        if($width < 360) {
            $img.attr('src', `${$imgPath}/320/hero_${index}.png`)
        }

        if($width >= 360) {
            $img.attr('src', `${$imgPath}/360/hero_${index}.png`)
        }

        if($width >= 576) {
            $img.attr('src', `${$imgPath}/576/hero_${index}.png`)
        }

        if($width >= 768) {
            $img.attr('src', `${$imgPath}/768/hero_${index}.png`)
        }

        if($width >= 992) {
            $img.attr('src', `${$imgPath}/992/hero_${index}.png`)
        }

        if($width > 1280 ) {
            $img.attr('src', `${$imgPath}/1280/hero_${index}.png`)
        }
    })
}

function onResized() {
    $(window).on({
        load: function () {
            changeImages()
        },
        resize: function () {
            changeImages()
        }
    })
}

function sendAjaxForm(ajax_form, url) {
    $.ajax({
        url: url,
        type: "POST",
        dataType: "html",
        data: $("#" + ajax_form).serialize(),
        success: function() {
            $('.js-main-content').addClass('d-none')
            $('.request-form').removeClass('d-none')
            $('.request__inner__success').removeClass('d-none')
            $('.request__inner__request').addClass('d-none')
            $(`#${ajax_form}`)[0].reset();
        }
    });
    return false;
}

function onSubmitForm() {
    $('button[type="submit"]').on({
        click: function (e) {
            e.preventDefault()

            const $form_id = $(this).attr("form");
            const $form = $(`form[id=${$form_id}]`);

            const $input_name = $(`input[name="name"][form=${$form_id}]`);
            const $input_phone = $(`input[name="phone"][form=${$form_id}]`);
            const $input_checkbox = $(`input[name="agreement"][form=${$form_id}]`);

            const $name = $form.find("input[name='name']").val();
            const $phone = $form
                .find("input[name='phone']")
                .val()
                .replace(/[^0-9]/g, "")

            const $isChecked = $input_checkbox.is(":checked")

            if($name.length === 0) {
                $input_name.addClass('error-input')
            } else {
                $input_name.removeClass('error-input')
            }

            if($phone.length < 11) {
                $input_phone.addClass('error-input')
            } else {
                $input_phone.removeClass('error-input')
            }

            if(!$isChecked) {
                $input_checkbox.addClass('error-input')
            } else {
                $input_checkbox.removeClass('error-input')
            }


            if ($name.length > 0 && $phone.length === 11 && $isChecked) {
                $form.unbind("submit").submit(function (e) {
                    e.preventDefault()
                });
                sendAjaxForm($form_id, '/wp-admin/admin-ajax.php?action=form')
            }
        }
    })
}